import React, { useState, useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IntroText from '../IntroText';
import TaskMenu from '../TaskMenu';
import { LinearProgress } from '@mui/material';

import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import ResidentOnboarding from '../ResidentOnboarding';
import RequiredTaskBox from './RequiredTaskBox';

import './index.css';

function PropertyRequiredTasks({
  serviceAddress,
  actions,
  residentOnboarding
}) {
  const [selectedSection, setSelectedSection] = useState(null);
  const [checklist, setChecklist] = useState(null);

  useEffect(() => {
    if (!selectedSection && serviceAddress && serviceAddress.Checklist) {
      const _section = serviceAddress.Checklist.filter(
        (section) => section.ServiceSectionID === 22
      )[0];

      setSelectedSection(_section);
    }

    if (
      (serviceAddress &&
        serviceAddress.Checklist &&
        serviceAddress.Address.ResidentID) ||
      residentID
    ) {
      actions.loadResidentOnboarding();
    }
  }, [serviceAddress]);

  useEffect(() => {
    if (residentOnboarding && residentOnboarding.Checklist) {
      let _itemDictionary = {};

      residentOnboarding.Checklist.filter(
        (item) =>
          item.OtherRequiredItemDescription &&
          item.OnboardingSection === 'pending'
      ).map((item) => {
        if (!_itemDictionary[item.OnboardingColumn]) {
          _itemDictionary[item.OnboardingColumn] = { ...item, itemCount: 1 };
        } else {
          _itemDictionary[item.OnboardingColumn].itemCount++;

          if (
            _itemDictionary[item.OnboardingColumn].ResidentOnboardingStatusID >
              item.ResidentOnboardingStatusID ||
            (_itemDictionary[item.OnboardingColumn]
              .ResidentOnboardingStatusID === 1 &&
              item.ResidentOnboardingStatusID === 1 &&
              !item.ResidentOnboardingStatusNameOverride)
          ) {
            _itemDictionary[item.OnboardingColumn].ResidentOnboardingStatusID =
              item.ResidentOnboardingStatusID;
            _itemDictionary[
              item.OnboardingColumn
            ].ResidentOnboardingStatusNameOverride = null;

            _itemDictionary[
              item.OnboardingColumn
            ].ResidentOnboardingStatusName = item.ResidentOnboardingStatusName;
          }
        }
      });

      setChecklist(
        Object.keys(_itemDictionary).map((itemKey) => _itemDictionary[itemKey])
      );

      // setChecklist(
      //   residentOnboarding.Checklist.filter(
      //     (item) =>
      //       item.OtherRequiredItemDescription &&
      //       item.OnboardingSection === 'pending'
      //   )
      // );
    }
  }, [residentOnboarding]);

  return selectedSection ? (
    <div className={'page-container property-required-tasks-container'}>
      {selectedSection ? (
        <h1>
          <TaskMenu section={selectedSection} page />

          <span className="keep-together">{selectedSection.Title}</span>
        </h1>
      ) : null}

      <IntroText
        trackAs="other-tasks"
        text={selectedSection.IntroText}
        mobileText={selectedSection.IntroTextMobile}
      />

      <div className="page">
        <div className="inner">
          {checklist ? (
            checklist.map((item) => (
              <RequiredTaskBox item={item} totalItems={checklist.length} />
            ))
          ) : (
            <LinearProgress />
          )}
        </div>
      </div>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyRequiredTasks);
