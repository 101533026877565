import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';

import { Button, IconButton, TextField, Tooltip, Alert } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';
import ResidentOnboardingStatusBadge from '../ResidentOnboarding/ResidentOnboardingStatusBadge';
import GeneralPopup from '../GeneralPopup';
import CustomerEventCalendar from '../CustomerEventCalendar';

import './index.css';

function RequiredTaskBox({ totalItems, item, residentOnboarding, actions }) {
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [showCalendar, setShowCalendar] = useState(null);

  return (
    <div className="required-task-card-container">
      <div className={'required-task-card'}>
        <ResidentOnboardingStatusBadge status={item} isProperty={false} />
        <div className="card-title">
          <h5>{item.OtherRequiredItemTitle}</h5>
        </div>
        <div className="card-icon">
          <img
            src={
              item.Grouping === 'vehicle'
                ? 'https://mi-provider-logo.s3-us-west-1.amazonaws.com/85630_Email_southeast.jfif'
                : item.Grouping === 'pet'
                ? 'https://mi-provider-logo.s3-us-west-1.amazonaws.com/85631_Email_dreamstime_xxl_133279622 png.png'
                : item.Grouping === 'reservation'
                ? 'https://mi-provider-logo.s3-us-west-1.amazonaws.com/85632_Email_Calen.png'
                : null
            }></img>
        </div>
        <div className="card-description">
          <p>{item.OtherRequiredItemDescription}</p>
        </div>
        <div className="card-buttons">
          <Button
            variant="outlined"
            className="each-button"
            onClick={() => {
              switch (item.Grouping) {
                case 'vehicle':
                case 'pet':
                  setShowResidentOnboarding(item);
                  break;
                case 'reservation':
                  setShowCalendar(
                    residentOnboarding.CalendarList.filter(
                      (calendar) => calendar.CalendarID === item.CalendarID
                    )[0]
                  );
                  break;
              }
            }}>
            {item.OtherRequiredItemButton1} <ChevronRightIcon />
          </Button>

          <Button
            variant="outlined"
            className={
              'each-button' + (!item.OtherRequiredItemButton2 ? ' hide' : '')
            }
            onClick={() => {
              switch (item.Grouping) {
                case 'vehicle':
                  actions
                    .saveResidentOnboarding({
                      ...residentOnboarding.Header,
                      HasNoVehicle: true
                    })
                    .then((result) => {
                      actions.loadResidentOnboarding();
                    });
                  break;
                case 'pet':
                  actions
                    .saveResidentOnboarding({
                      ...residentOnboarding.Header,
                      HasNoPet: true
                    })
                    .then((result) => {
                      actions.loadResidentOnboarding();
                    });
                  break;
              }
            }}>
            {item.OtherRequiredItemButton2} <ChevronRightIcon />
          </Button>
        </div>
      </div>
      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          otherTasks={showResidentOnboarding.Grouping}
          closeFunc={() => {
            setShowResidentOnboarding(false);
            actions.loadResidentOnboarding();
          }}
        />
      ) : null}
      {showCalendar ? (
        <GeneralPopup
          title={showCalendar.CalendarName}
          message={
            <>
              {showCalendar.Description ? (
                <Alert
                  severity="info"
                  className="top-notice"
                  style={{ marginTop: '20px' }}>
                  {showCalendar.Description}
                </Alert>
              ) : null}

              <CustomerEventCalendar
                calendar={showCalendar}
                minDate={residentOnboarding.Header.DateLeaseStart}
                residentID={residentOnboarding.Header.ResidentID}
              />
            </>
          }
          closeFunc={() => {
            setShowCalendar(null);
            actions.loadResidentOnboarding();
          }}
          closeLabel={'Close'}
          className={'calendar-timeslot-popup'}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadResidentOnboarding: bindActionCreators(
        residentOnboardingActions.loadResidentOnboarding,
        dispatch
      ),
      saveResidentOnboarding: bindActionCreators(
        residentOnboardingActions.saveResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequiredTaskBox);
